import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Layout from "../components/Layout"
import OpenTable from "../components/OpenTable"

const Reservations = () => {
  return (
    <Layout pageTitle="Reservations">
      <Page>
        <OpenTable />
      </Page>
    </Layout>
  )
}

const Page = styled.div`
  padding: 2rem 1.5rem;
  background: ${({ theme }) => theme.primary};
`

export default Reservations
