import React from "react"
import styled from "styled-components"

export default function OpenTable() {
  const createMarkup = () => {
    return {
      __html: `<script src="//www.opentable.com/widget/reservation/loader?rid=1085665&type=standard&theme=standard&color=1&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website"></script>`,
    }
  }

  const createWidget = () => ({
    __html: `<img height="250px" width="250px" src="https://restaurant.opentable.com/badge/ot/DC-2022.png">`,
  })

  return (
    <Center>
      <script
        type="text/javascript"
        src="//www.opentable.com/widget/reservation/loader?rid=1085665&type=standard&theme=standard&iframe=true&lang=en-US&newtab=false&ot_source=Restaurant%20website"
      ></script>

      <div dangerouslySetInnerHTML={createMarkup()}></div>
      <div style={{ margin: "0 2rem" }}></div>
      <div dangerouslySetInnerHTML={createWidget()}></div>
    </Center>
  )
}

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  iframe {
    width: 300px;
    height: 301px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`
